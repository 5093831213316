import { useEffect, useState } from 'react';
import { useNavigate, Link, Outlet, useLocation } from 'react-router-dom';
import { fetchBathroomPage, RoomData } from '../Data/kitchenBathroomData';

export default function Bathroom() {
  const [bathroomData, setBathroomData] = useState<RoomData | null>(null);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const loadBathroomData = async () => {
      try {
        const data = await fetchBathroomPage();
        setBathroomData(data);
      } catch (error) {
        console.error('Error loading bathroom data:', error);
      }
    };

    loadBathroomData();

    if (location.pathname === '/bathroom') {
      navigate('modern');
    }
  }, [navigate, location.pathname]);

  if (!bathroomData) {
    return <div>Loading...</div>;
  }

  return (
    <div>
      {/* Breadcrumb style links for Modern and Traditional Bathrooms */}
      <div className="mt-16 max-w-screen-lg mx-auto text-center">
        <p className="text-lg font-medium text-gray-700 mb-6">
          Choose between our Modern or Traditional Bathroom designs
        </p>

        <div className="flex justify-center space-x-8 text-sm tracking-wider font-light">
          {/* Modern Bathroom Link */}
          <Link
            to="modern"
            className={`relative inline-block px-6 py-3 border rounded-lg transition-all duration-300 ${
              location.pathname.includes('modern')
                ? 'text-white bg-black font-semibold'
                : 'text-gray-600 border-gray-300 hover:bg-gray-100'
            }`}
          >
            Modern Bathrooms
          </Link>

          {/* Traditional Bathroom Link */}
          <Link
            to="traditional"
            className={`relative inline-block px-6 py-3 border rounded-lg transition-all duration-300 ${
              location.pathname.includes('traditional')
                ? 'text-white bg-black font-semibold'
                : 'text-gray-600 border-gray-300 hover:bg-gray-100'
            }`}
          >
            Traditional Bathrooms
          </Link>
        </div>
      </div>



      {/* Outlet to render the selected (Modern/Traditional) component */}
      <div className="mt-10">
        <Outlet /> {/* This is where Modern/Traditional component will render without reloading the whole page */}
      </div>
    </div>
  );
}
