import { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { ChevronDownIcon } from '@heroicons/react/24/solid';
import { fetchServicesData, ServiceData } from '../Data/serviceData';

type NavigationLink = {
  name: string;
  path: string;
  isPrimary?: boolean;
};

const Header = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isServicesDropdownOpen, setIsServicesDropdownOpen] = useState(false);
  const [services, setServices] = useState<ServiceData[]>([]);
  const location = useLocation();

  const navigationLinks: NavigationLink[] = [
    { name: "HOME", path: "/" },
    { name: "ABOUT", path: "/about" },
    { name: "KITCHEN", path: "/kitchen" },
    { name: "BATHROOM", path: "/bathroom" },
    { name: "PROJECTS", path: "/projects" },
  ];

  const toggleMobileMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const toggleServicesDropdown = () => {
    setIsServicesDropdownOpen(!isServicesDropdownOpen);
  };

  useEffect(() => {
    const fetchServices = async () => {
      try {
        const servicesData = await fetchServicesData();
        setServices(servicesData);
      } catch (error) {
        console.error('Error fetching services data:', error);
      }
    };
    
    fetchServices();
  }, []);

  return (
    <header className="bg-white font-cursive tracking-wide shadow-md relative z-50 w-full">
      <nav className="sticky top-0 z-50 bg-white px-4 py-4 lg:px-6 shadow-md">
        <div className="flex items-center justify-between mx-auto max-w-screen-xl">
          {/* Logo aligned left */}
          <a href="/" className="flex-shrink-0">
            <img src="/images/logos/logo.png" alt="logo" className="w-36" />
          </a>

          {/* Menu Items (hidden on mobile) */}
          <div
            id="collapseMenu"
            className={`${
              isMenuOpen ? "block" : "hidden lg:block"
            } max-lg:before:fixed max-lg:before:bg-black max-lg:before:opacity-90 max-lg:before:inset-0 max-lg:before:z-50`}
          >
            <ul className="lg:flex gap-x-5 max-lg:fixed max-lg:bg-white max-lg:w-1/2 max-lg:min-w-[300px] max-lg:top-0 max-lg:left-0 max-lg:p-6 max-lg:h-full max-lg:shadow-md max-lg:overflow-auto z-50">
              {navigationLinks.map((link, index) => (
                <li
                  key={index}
                  className={`max-lg:border-b border-gray-300 max-lg:py-3 px-3 ${
                    link.isPrimary && "lg:hidden"
                  }`}
                >
                  <a
                    href={link.path}
                    className={`${
                      location.pathname === link.path
                        ? "text-gray-700 underline"
                        : "text-gray-500"
                    } hover:text-gray-600 block font-light uppercase text-[15px]`}
                  >
                    {link.name}
                  </a>
                </li>
              ))}

              {/* Services Dropdown with Padding Fix */}
              <li className="relative max-lg:py-3 px-3 border-b border-gray-300">
                <button
                  className="flex items-center text-gray-500 hover:text-gray-600 font-light uppercase text-[15px]"
                  onClick={toggleServicesDropdown}
                >
                  Services
                  <ChevronDownIcon className={`w-5 h-5 ml-1 transition-transform duration-300 ${isServicesDropdownOpen ? 'rotate-180' : ''}`} />
                </button>
                {isServicesDropdownOpen && (
                  <ul className="absolute mt-2 w-48 bg-white border border-gray-200 shadow-lg z-50">
                    {services.map((service) => (
                      <li key={service.id} className="border-b last:border-none">
                        <a
                          href={`/services/${service.id}`}
                          className="block px-4 py-2 text-gray-700 hover:bg-gray-100"
                        >
                          {service.title}
                        </a>
                      </li>
                    ))}
                  </ul>
                )}
              </li>

              {/* BOOK FREE CONSULTATION placed at bottom of mobile */}
              <li className="lg:hidden max-lg:py-3 px-3">
                <a
                  href="/contact"
                  className="block text-gray-700 hover:text-gray-600 font-light uppercase text-[15px]"
                >
                  BOOK FREE CONSULTATION
                </a>
              </li>
            </ul>
          </div>

          {/* Contact Button aligned right, only visible on desktop */}
          <div className="hidden lg:flex space-x-3">
            <a
              href="/contact"
              className="px-4 py-2 text-sm font-bold text-white bg-gray-700"
            >
              BOOK FREE CONSULTATION
            </a>
          </div>

          {/* Hamburger Button visible on mobile */}
          <div className="flex lg:hidden">
            <button
              id="toggleOpen"
              className="lg:hidden"
              onClick={toggleMobileMenu}
            >
              <div className="block w-6 relative z-50">
                <span
                  className={`block h-0.5 ${
                    isMenuOpen ? "bg-white" : "bg-black"
                  } mb-1.5 transition-transform duration-300 ${
                    isMenuOpen ? "transform rotate-45 translate-y-2" : ""
                  }`}
                ></span>
                <span
                  className={`block h-0.5 ${
                    isMenuOpen ? "bg-white" : "bg-black"
                  } mb-1.5 transition-opacity duration-300 ${
                    isMenuOpen ? "opacity-0" : ""
                  }`}
                ></span>
                <span
                  className={`block h-0.5 ${
                    isMenuOpen ? "bg-white" : "bg-black"
                  } transition-transform duration-300 ${
                    isMenuOpen ? "transform -rotate-45 -translate-y-2" : ""
                  }`}
                ></span>
              </div>
            </button>
          </div>
        </div>
      </nav>
    </header>
  );
};

export default Header;
