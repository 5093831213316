import React from 'react';
import { FaArrowLeft, FaArrowRight, FaTimes } from 'react-icons/fa';

interface FullscreenImageProps {
  imageUrl: string;
  onPrevious: () => void;
  onNext: () => void;
  onClose: () => void;
}

const FullscreenImage: React.FC<FullscreenImageProps> = ({ imageUrl, onPrevious, onNext, onClose }) => {
  const handleClickOutside = (e: React.MouseEvent<HTMLDivElement>) => {
    if (e.target === e.currentTarget) {
      onClose();
    }
  };

  return (
    <div 
      className="fixed inset-0 bg-black bg-opacity-90 flex items-center justify-center z-[9999]" 
      onClick={handleClickOutside}
      style={{ height: '100vh', overflow: 'hidden' }} // Force fullscreen container height to 100vh
    >
      <img
        src={imageUrl}
        alt="fullscreen"
        className="object-contain max-h-full max-w-full h-auto w-auto" // Ensure image fits within container
        style={{ maxHeight: '95%', maxWidth: '95%' }} // Ensures it doesn't overflow or cause scrolling
      />

      {/* Close Button */}
      <button
        className="absolute top-5 right-5 text-white text-3xl z-50"
        onClick={onClose}
      >
        <FaTimes />
      </button>

      {/* Previous Button */}
      <button
        className="absolute left-5 top-1/2 transform -translate-y-1/2 bg-gray-800 text-white text-2xl p-2 rounded-full z-50"
        onClick={(e) => {
          e.stopPropagation();
          onPrevious();
        }}
      >
        <FaArrowLeft />
      </button>

      {/* Next Button */}
      <button
        className="absolute right-5 top-1/2 transform -translate-y-1/2 bg-gray-800 text-white text-2xl p-2 rounded-full z-50"
        onClick={(e) => {
          e.stopPropagation();
          onNext();
        }}
      >
        <FaArrowRight />
      </button>
    </div>
  );
};

export default FullscreenImage;
