import React from "react";
import { FaPhoneAlt, FaEnvelope } from "react-icons/fa";

export default function AboveHeader() {
  return (
    <div className="hidden lg:block bg-gray-800 text-white py-2">
      <div className="max-w-screen-xl mx-auto flex justify-between items-center px-4 lg:px-6">
        <div className="flex space-x-4 items-center">
          <a href="tel:+447477534661" className="flex items-center text-sm hover:underline">
            <FaPhoneAlt className="mr-2" />
            07477 534661
          </a>
          <a href="mailto:info@sampsonco.com" className="flex items-center text-sm hover:underline">
            <FaEnvelope className="mr-2" />
            info@sampsonco.com
          </a>
        </div>
        <div className="flex space-x-4">
          <img
            src="/images/logos/logowhite.png"
            alt="Sampson N Co Logo"
            className="h-8" // Adjust the height as needed
          />
        </div>
      </div>
    </div>
  );
}
