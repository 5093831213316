import React, { useEffect, useState } from 'react';
import KitchenBathroomHeader from '../CustomComponents/KitchenBathroomHeader';
import Head from '../PageComponents/KitchenBathroomComponent/Head';
// import PricingTable from '../Data/pricingTable'; // Import the new PricingTable component
import { fetchKitchenPage, RoomData } from '../Data/kitchenBathroomData';
import ImageGallery from '../PageComponents/GalleryComponent/imagegallery';
import KitchenMade from '../PageComponents/KitchenComponents/KitchenMade';
import Testimonial from '../CustomComponents/testimonialReusable';
import CTA from '../CustomComponents/cta';

export default function Kitchen() {
  const [kitchenData, setKitchenData] = useState<RoomData | null>(null);

  useEffect(() => {
    const loadKitchenData = async () => {
      try {
        const data = await fetchKitchenPage();
        setKitchenData(data);
      } catch (error) {
        console.error('Error loading kitchen data:', error);
      }
    };

    loadKitchenData();
  }, []);

  if (!kitchenData) {
    return <div>Loading...</div>;
  }

  return (
    <div>
      <KitchenBathroomHeader
        images={['/images/Kitchen/JQELB7tw.webp']}
        title='Kitchens'
        subtitle=""
        height="60vh"
      />
      <Head
        heading={'Quality German Kitchens'}
        images={['/images/Kitchen/jVeT7Fuw.webp']}
        paragraphs={['Our kitchens inspire in so many ways. They are created in a ultra-modern production facility in Germany with astonishing attention to detail. Not only do they look fantastic, the quality is also proven in their daily use. Superbly manufactured kitchen furniture go hand in hand with perfected fittings technology and a carefully thought-out interior in all our products.']}
      />
      <Testimonial
        image="/images/peterreview.png"
        description='Our new kitchen arrived on 27th September (on time) and to say we are pleased with it is an understatement. The build quality is of the highest standard as are the doors,hinges and all components. Sampson N co Kitchens were superb, they were professional and accommodating at all times and when I changed anything on the plans I was met with a "no problem" attitude which was very satisfying. This company is highly recommended.'
        name="Peter King"
        projectType="Kitchen Renovation"
        bgColor="light"  // Light background with dark text
      />
      <KitchenMade />
      {/* <PricingTable
        roomType="Kitchen" // or "Kitchen"
        title="Kitchen Pricing Guide"
        subtitle="Choose the best plan for your kitchen"
        extraInfo="All prices include VAT and installation."
      /> */}
      <CTA />
      <ImageGallery category="kitchen"  />
    </div>
  );
}
