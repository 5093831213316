import React, { useState, useEffect, useRef } from 'react';
import { useParams, Link } from 'react-router-dom';
import { useProjects } from '../../CustomComponents/projectsContext';
import FullscreenImage from '../../CustomComponents/FullScreenImage';

const ProjectsDetails: React.FC = () => {
  const { id } = useParams<{ id: string }>(); // `id` is a string here
  const { projects } = useProjects();

  // Convert the URL param `id` (string) to a number
  const projectId = Number(id); // Convert to number
  const project = projects.find((project) => project.id === projectId); // Compare with number

  const [isVisible, setIsVisible] = useState(false);
  const sectionRef = useRef<HTMLDivElement>(null);
  const [currentImageIndex, setCurrentImageIndex] = useState<number | null>(null);

  useEffect(() => {
    const handleScroll = () => {
      if (sectionRef.current) {
        const { top } = sectionRef.current.getBoundingClientRect();
        const windowHeight = window.innerHeight;
        if (top < windowHeight * 0.75) {
          setIsVisible(true);
        }
      }
    };

    window.addEventListener('scroll', handleScroll);
    handleScroll();

    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  if (!project) {
    return <div>Project not found</div>;
  }

  // Function to split the description into paragraphs
  const renderDescription = (description: string) => {
    return description.split('\n\n').map((paragraph, index) => (
      <p key={index} className="text-lg text-gray-700 mb-6">
        {paragraph.trim()}
      </p>
    ));
  };

  return (
    <div
      ref={sectionRef}
      className={`bg-gray-100 pb-16 transition-all duration-1000 transform ${
        isVisible ? 'opacity-100 translate-y-0' : 'opacity-0 translate-y-10'
      }`}
    >
      <div className="container mx-auto px-6 lg:px-8 max-w-screen-xl">
        <Link to="/projects" className="text-gray-800 hover:text-gray-600 mt-4 mb-4 inline-block">
          &larr; Back to All Projects
        </Link>

        <h1 className="text-4xl font-light mb-8 text-gray-900 leading-tight">{project.title}</h1>

        {/* Image + Text Flow Layout */}
        <div className="grid grid-cols-1 gap-y-12">
          {/* First Image */}
          <div>
            <img
              src={project.images[0]}
              alt="Main"
              className="w-full h-auto object-cover mb-6"
            />
            {/* All description under first image */}
            <div>{renderDescription(project.description)}</div>
          </div>

          {/* Rest of the images in 2s */}
          <div className="grid grid-cols-1 sm:grid-cols-2 gap-6">
            {project.images.slice(1).map((image, index) => (
              <img
                key={`image-${index}`}
                src={image}
                alt={`Project  ${index + 2}`}
                className="w-full h-auto object-cover"
              />
            ))}
          </div>
        </div>

        {/* Fullscreen Image Mode */}
        {currentImageIndex !== null && project.images && (
          <FullscreenImage
            imageUrl={project.images[currentImageIndex]}
            onPrevious={() =>
              setCurrentImageIndex(
                (currentImageIndex - 1 + project.images.length) % project.images.length
              )
            }
            onNext={() =>
              setCurrentImageIndex(
                (currentImageIndex + 1) % project.images.length
              )
            }
            onClose={() => setCurrentImageIndex(null)}
          />
        )}

        {/* Similar Projects Section */}
        <div className="w-full mt-16">
          <h2 className="text-3xl font-light mb-8">Similar Projects</h2>
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8">
            {projects
              .filter((proj) => proj.id !== projectId) // Compare using the number
              .slice(0, 3)
              .map((proj) => (
                <article
                  key={proj.id}
                  className="relative isolate flex flex-col justify-end overflow-hidden bg-gray-900 px-8 pb-8 pt-80 sm:pt-48 lg:pt-80 transition-transform duration-300 transform hover:scale-105"
                >
                  <img
                    src={proj.images[0]}
                    alt={proj.title}
                    className="absolute inset-0 -z-10 h-full w-full object-cover"
                  />
                  <div className="absolute inset-0 -z-10 bg-gradient-to-t from-black via-black/40" />
                  <h3 className="mt-3 text-lg font-light text-white">
                    <Link to={`/projects/${proj.id}`}>
                      <span className="absolute inset-0" />
                      {proj.title}
                    </Link>
                  </h3>
                  <p className="mt-2 text-sm text-gray-300">
                    {proj.description.substring(0, 100)}...
                  </p>
                </article>
              ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProjectsDetails;
