// Types for Pricing and Room Data
export interface PricingTier {
  name: string;
  id: string;
  priceTotal: string;
  description: string;
  features: string[];
  mostPopular: boolean;
}

export interface RoomData {
  headline: string;
  headlineImage: string;
  mainImage: string;
  description: string;
  pricingTiers: PricingTier[];
  images: string[];
  introForHomepage: string;
}

// Sample Data for the Kitchen Page
export const fetchKitchenPage = async (): Promise<RoomData> => {
  return {
    headline: 'Sleek and Modern Kitchen Designs',
    headlineImage: '/images/kitchen-headline.jpg',
    mainImage: '/images/kitchen-main.jpg',
    description: 'Our kitchens are designed to offer both elegance and functionality. With custom-built cabinetry and modern appliances, every aspect is crafted to perfection.',
    pricingTiers: [
      {
        name: 'Basic Kitchen',
        id: 'basic-kitchen',
        priceTotal: '£8,000',
        description: 'Perfect for small homes or rentals, this includes basic cabinetry, countertops, and appliance setup.',
        features: ['Basic cabinetry', 'Granite countertops', 'Standard appliances'],
        mostPopular: false,
      },
      {
        name: 'Luxury Kitchen',
        id: 'luxury-kitchen',
        priceTotal: '£25,000',
        description: 'A premium kitchen remodel with the finest materials and advanced technologies.',
        features: ['Custom cabinetry', 'Quartz countertops', 'Smart appliances', 'Island counter'],
        mostPopular: true,
      },
    ],
    images: ['/images/kitchen1.jpg', '/images/kitchen2.jpg', '/images/kitchen3.jpg'],
    introForHomepage: 'Transform your kitchen into the heart of your home with our modern designs.',
  };
};

// Sample Data for the General Bathroom Page
export const fetchBathroomPage = async (): Promise<RoomData> => {
  return {
    headline: 'Elegant Bathroom Renovations',
    headlineImage: '/images/bathroom-headline.jpg',
    mainImage: '/images/bathroom-main.jpg',
    description: 'Our bathroom designs offer a perfect blend of luxury and utility, creating spa-like retreats in the comfort of your home.',
    pricingTiers: [
      {
        name: 'Standard Bathroom',
        id: 'standard-bathroom',
        priceTotal: '£7,000',
        description: 'A full renovation of a medium-sized bathroom with modern fixtures and fittings.',
        features: ['Modern sink and vanity', 'Bathtub or shower enclosure', 'Ceramic tiles'],
        mostPopular: false,
      },
      {
        name: 'Luxury Bathroom',
        id: 'luxury-bathroom',
        priceTotal: '£20,000',
        description: 'Complete bathroom redesign with high-end finishes and custom features.',
        features: ['Freestanding bathtub', 'Walk-in rain shower', 'Marble countertops', 'Heated flooring'],
        mostPopular: true,
      },
    ],
    images: ['/images/bathroom1.jpg', '/images/bathroom2.jpg', '/images/bathroom3.jpg'],
    introForHomepage: 'Relax in style with our luxury bathroom renovations.',
  };
};

// Sample Data for Modern Bathroom Page
export const fetchModernBathroomPage = async (): Promise<RoomData> => {
  return {
    headline: 'Modern Bathrooms with Sleek Designs',
    headlineImage: '/images/modern-bathroom-headline.jpg',
    mainImage: '/images/modern-bathroom-main.jpg',
    description: 'Modern bathrooms with sleek, minimalistic design and state-of-the-art fixtures.',
    pricingTiers: [
      {
        name: 'Minimalist Modern Bathroom',
        id: 'minimalist-modern-bathroom',
        priceTotal: '£15,000',
        description: 'A stylish, minimalist bathroom with clean lines and modern amenities.',
        features: ['Wall-mounted sinks', 'Frameless glass shower', 'Modern fixtures'],
        mostPopular: true,
      },
    ],
    images: ['/images/modern-bathroom1.jpg', '/images/modern-bathroom2.jpg'],
    introForHomepage: 'Bring the modern touch to your home with our minimalist bathroom designs.',
  };
};

// Sample Data for Traditional Bathroom Page
export const fetchTraditionalBathroomPage = async (): Promise<RoomData> => {
  return {
    headline: 'Traditional Bathrooms with Timeless Elegance',
    headlineImage: '/images/traditional-bathroom-headline.jpg',
    mainImage: '/images/traditional-bathroom-main.jpg',
    description: 'Our traditional bathroom designs offer a timeless and elegant appeal, perfect for homes looking to maintain a classic aesthetic.',
    pricingTiers: [
      {
        name: 'Classic Traditional Bathroom',
        id: 'classic-traditional-bathroom',
        priceTotal: '£12,000',
        description: 'A traditional bathroom renovation with classic fixtures and fittings.',
        features: ['Clawfoot bathtub', 'Vintage fixtures', 'Classic tiling'],
        mostPopular: false,
      },
      {
        name: 'Premium Traditional Bathroom',
        id: 'premium-traditional-bathroom',
        priceTotal: '£25,000',
        description: 'A high-end traditional bathroom with premium materials and design elements.',
        features: ['Marble countertops', 'Ornate mirrors', 'Luxury brass fixtures', 'Heated towel rails'],
        mostPopular: true,
      },
    ],
    images: ['/images/traditional-bathroom1.jpg', '/images/traditional-bathroom2.jpg'],
    introForHomepage: 'Step into timeless beauty with our traditional bathroom designs.',
  };
};
