import React from 'react';
import KitchenBathroomHeader from '../CustomComponents/KitchenBathroomHeader';
import Head from '../PageComponents/KitchenBathroomComponent/Head';
import ImageGallery from '../PageComponents/GalleryComponent/imagegallery';
import TraditionalBathroomFeatures from '../PageComponents/KitchenBathroomComponent/TraditionalInfo';

export default function TraditionalBathroom() {
  const [traditionalBathroomData] = React.useState({
    headline: 'Elegant and Timeless Traditional Bathrooms',
    mainImage: '/images/TraditionalBathroom/kensington-baths-v1-ls-671787ff60c3a.webp',
    description: `
      Our traditional bathrooms combine timeless elegance with modern convenience, crafted with precision in Stoke-on-Trent, the heart of the English Potteries. 
      
      Inspired by classic designs, each piece showcases the finest craftsmanship passed down through generations. Whether it's the bold, Art Deco-inspired Empire suite or the confident square forms of the Victorian collection, our traditional bathrooms bring a refined touch of heritage to your home. Each ceramic piece is handcrafted and glazed to perfection, offering durability and beauty that stands the test of time.
    `,
    images: [
      '/images/TraditionalBathroom/kensington-baths-v1-ls-671787ff60c3a.webp',
    ],
  });

  return (
    <div>
      <KitchenBathroomHeader
        images={['/images/TraditionalBathroom/empire-671787fd7fb3d.webp']}
        title="Traditional Bathrooms"
        subtitle=""
        height="60vh"
      />
      <Head
        heading={traditionalBathroomData.headline}
        images={[traditionalBathroomData.mainImage]}
        paragraphs={[traditionalBathroomData.description]}
      />
      <TraditionalBathroomFeatures />
      {/* Image Gallery */}
      <ImageGallery category="traditional-bathroom" />
    </div>
  );
}
