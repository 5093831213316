import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const ScrollToTop: React.FC = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    // List of routes where ScrollToTop should be disabled
    const excludedRoutes = ['/bathroom', '/bathroom/modern', '/bathroom/traditional'];

    // Check if current route is not in the excluded list
    if (!excludedRoutes.includes(pathname)) {
      window.scrollTo(0, 0);
    }
  }, [pathname]);

  return null;
};

export default ScrollToTop;
