export default function CTA() {
  return (
    <div className="bg-white">
      <div className="mx-auto max-w-7xl py-24 sm:px-6 sm:py-32 lg:px-8">
        <div
          className="relative isolate overflow-hidden px-6 py-24 text-center shadow-2xl sm:px-16 sm:rounded-none"
          style={{
            backgroundImage: "url('/images/JayneRichard-1.png')",
            backgroundSize: 'cover',
            backgroundPosition: 'center',
          }}
        >
          {/* Darker overlay for more contrast */}
          <div className="absolute inset-0 bg-black opacity-70"></div>

          <h2 className="relative mx-auto max-w-2xl text-4xl font-light tracking-tight text-white sm:text-5xl leading-tight">
            Get Your Free Consultation Today
          </h2>
          <p className="relative mx-auto mt-6 max-w-xl text-lg font-light leading-relaxed text-white">
            Transform your home with our expert kitchen and bathroom design services. Contact us for a free consultation and start your journey to a beautifully crafted space.
          </p>
          <div className="relative mt-10 flex items-center justify-center gap-x-6">
            <a
              href="/contact"
              className="bg-white px-4 py-3 text-sm font-semibold text-gray-900 shadow-sm hover:bg-gray-100 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-white"
            >
              Contact Us
            </a>
            <a href="/about" className="text-sm font-semibold leading-6 text-white hover:text-gray-300">
              Learn more <span aria-hidden="true">→</span>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}
