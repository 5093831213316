import React from 'react';

const features = [
  {
    name: 'Traditional Handcrafted Skills',
    description:
      'Our experienced team use hand-crafted skills passed down through generations in and around Stoke-On-Trent, the true heart of The Potteries.',
    imageSrc: '/images/traditional/1.png', // Path to image 1
  },
  {
    name: 'Ceramic Craftsmanship',
    description:
      'Each ceramic piece is moulded, glazed and crafted by hand to the very highest specification.',
    imageSrc: '/images/traditional/2.png', // Path to image 2
  },
  {
    name: 'Fine China Clay Production',
    description:
      'Fine China Clay, sourced in the UK, is taken from the mould and is finished and sprayed by hand before firing. Each piece is 100% quality checked at every production stage, allowing us to achieve perfection that exceeds industry standards and customer expectations.',
    imageSrc: '/images/traditional/3.png', // Path to image 3
  },
];

const TraditionalBathroomFeatures: React.FC = () => {
  return (
    <div className="bg-white py-24 sm:py-32">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto max-w-2xl lg:mx-0">
          <h2 className="text-3xl font-light tracking-wide text-gray-900 sm:text-4xl font-poppins">
            How Our Traditional Bathrooms Are Made
          </h2>
          <p className="mt-6 text-lg font-light tracking-wide leading-8 text-gray-600 font-poppins">
            Each piece is hand-crafted using traditional methods to create beautiful, timeless designs that will last for generations.
          </p>
        </div>
        <div className="mx-auto mt-16 max-w-2xl sm:mt-20 lg:mt-24 lg:max-w-none">
          <dl className="grid max-w-xl grid-cols-1 gap-x-8 gap-y-16 lg:max-w-none lg:grid-cols-3">
            {features.map((feature) => (
              <div key={feature.name} className="flex flex-col">
                {/* Image for each feature (with square styling) */}
                <div className="mb-6">
                  <img
                    src={feature.imageSrc}
                    alt={feature.name}
                    className="w-full h-64 object-cover" // Square image, zoomed if necessary
                    style={{ aspectRatio: '1 / 1' }} // Enforce square aspect ratio
                  />
                </div>
                {/* Feature Title */}
                <dt className="text-base font-light tracking-wide text-gray-900 font-poppins">
                  {feature.name}
                </dt>
                {/* Feature Description */}
                <dd className="mt-2 text-base font-light tracking-wide leading-7 text-gray-600 font-poppins">
                  {feature.description}
                </dd>
              </div>
            ))}
          </dl>
        </div>
      </div>
    </div>
  );
};

export default TraditionalBathroomFeatures;
