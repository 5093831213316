import React from 'react';

interface TestimonialProps {
  image: string;
  description: string;
  name: string;
  projectType: string;
  bgColor?: 'dark' | 'light';
}

const Testimonial: React.FC<TestimonialProps> = ({
  image,
  description,
  name,
  projectType,
  bgColor = 'dark', // Default to dark background
}) => {
  const isDark = bgColor === 'dark';

  return (
    <div className={`${isDark ? 'bg-gray-900' : 'bg-gray-100'} pb-16 pt-24 sm:pb-24 sm:pt-32 xl:pb-32`}>
      <div className={`${isDark ? 'bg-gray-900' : 'bg-white'} pb-20 sm:pb-24 xl:pb-0`}>
        <div className="mx-auto flex max-w-7xl flex-col items-center gap-x-8 gap-y-10 px-6 sm:gap-y-8 lg:px-8 xl:flex-row xl:items-stretch">
          <div className="-mt-8 w-full max-w-2xl xl:-mb-8 xl:w-96 xl:flex-none">
            <div className="relative aspect-[2/1] h-full md:-mx-8 xl:mx-0 xl:aspect-auto">
              <img
                alt=""
                src={image}
                className="absolute inset-0 h-full w-full bg-gray-800 object-cover shadow-2xl"
              />
            </div>
          </div>
          <div className={`w-full max-w-2xl xl:max-w-none xl:flex-auto xl:px-16 xl:py-24`}>
            <figure className="relative isolate pt-6 sm:pt-12">
              <blockquote className={`text-sm font-light italic leading-6 ${isDark ? 'text-white' : 'text-gray-900'} sm:text-base sm:leading-7`}>
                <p>&ldquo;{description}&rdquo;</p>
              </blockquote>
              <figcaption className="mt-8 text-base">
                <div className={`font-semibold ${isDark ? 'text-white' : 'text-gray-900'}`}>{name}</div>
                <div className={`mt-1 ${isDark ? 'text-gray-400' : 'text-gray-600'}`}>{projectType}</div>
              </figcaption>
            </figure>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Testimonial;
