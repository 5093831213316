import React, { useRef, useState, useEffect } from 'react';

interface HeadProps {
  heading: string;
  images: string[];
  paragraphs: string[];
}

const Head: React.FC<HeadProps> = ({ heading, images, paragraphs }) => {
  const sectionRef = useRef<HTMLDivElement>(null);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, 4000); // Change every 4 seconds

    return () => clearInterval(intervalId);
  }, [images.length]);

  // Function to process paragraphs with custom gaps (e.g., "---" as a marker)
  const processTextWithGaps = (text: string) => {
    return text
      .split('---') // Split the text on the "---" marker
      .map(
        (part, index) =>
          `<p style="margin-bottom: 24px;">${part.trim().replace(/\n/g, '<br />')}</p>`
      ) // Replace newlines with <br /> and wrap sections in <p>
      .join(''); // Join the parts into a single HTML string
  };

  return (
    <div className="bg-gray-50 py-12"> {/* Light background */}
      <div ref={sectionRef} className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto grid max-w-2xl grid-cols-1 items-start gap-x-8 gap-y-16 sm:gap-y-24 lg:mx-0 lg:max-w-none lg:grid-cols-2">
          {/* Text section */}
          <div className="lg:pt-10"> {/* Added padding for spacing */}
            <div className="text-base leading-7 text-gray-800 lg:max-w-lg"> {/* Dark text */}
            <h1 className="mt-2 text-3xl font-light tracking-tight text-gray-900 sm:text-4xl mb-6 leading-relaxed">
              {heading}
            </h1>
              <div className="max-w-xl text-lg text-gray-600 space-y-6"> {/* Space between paragraphs */}
                {/* Process each paragraph */}
                {paragraphs.map((paragraph, index) => (
                  <div
                    key={index}
                    dangerouslySetInnerHTML={{
                      __html: processTextWithGaps(paragraph),
                    }}
                  />
                ))}
              </div>
            </div>
          </div>
          {/* Image section */}
          <div className="relative overflow-hidden lg:h-full lg:pl-4 lg:sticky lg:top-24 lg:flex lg:items-stretch">
            {images.map((image, index) => (
              <img
                key={index}
                className={`w-full h-auto object-contain transition-opacity duration-1000 ${ // Image retains aspect ratio
                  index === currentImageIndex ? 'opacity-100' : 'opacity-0'
                }`}
                src={image}
                alt={`Slide ${index + 1}`}
                loading={index === 0 ? 'eager' : 'lazy'} // Load the first image eagerly
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Head;

