export interface ImageItem {
  type: 'image';
  imageUrl: string;
  category: 'kitchen' | 'modern-bathroom' | 'traditional-bathroom';
}

const placeholderImages: ImageItem[] = [
  // Kitchen Images
  {
    type: 'image',
    imageUrl: '/images/Kitchen/_IhIoytA.webp',
    category: 'kitchen',
  },
  {
    type: 'image',
    imageUrl: '/images/Kitchen/4wt1U8Kw.webp',
    category: 'kitchen',
  },
  {
    type: 'image',
    imageUrl: '/images/Kitchen/9A1d_2wg.webp',
    category: 'kitchen',
  },
  {
    type: 'image',
    imageUrl: '/images/Kitchen/BdRJMMxQ.webp',
    category: 'kitchen',
  },
  {
    type: 'image',
    imageUrl: '/images/Kitchen/BNQYYTmA.webp',
    category: 'kitchen',
  },
  {
    type: 'image',
    imageUrl: '/images/Kitchen/Hlb109Jg.webp',
    category: 'kitchen',
  },
  {
    type: 'image',
    imageUrl: '/images/Kitchen/n9FTTB4w.webp',
    category: 'kitchen',
  },
  {
    type: 'image',
    imageUrl: '/images/Kitchen/QXIxi3qw.webp',
    category: 'kitchen',
  },
  {
    type: 'image',
    imageUrl: '/images/Kitchen/TVYR5PDQ.webp',
    category: 'kitchen',
  },
  {
    type: 'image',
    imageUrl: '/images/Kitchen/Wm2AAIgw.webp',
    category: 'kitchen',
  },
  {
    type: 'image',
    imageUrl: '/images/Kitchen/_sIirgvA.webp',
    category: 'kitchen',
  },
  {
    type: 'image',
    imageUrl: '/images/Kitchen/2B_MeqLQ.webp',
    category: 'kitchen',
  },
  {
    type: 'image',
    imageUrl: '/images/Kitchen/2mWOU1zw.webp',
    category: 'kitchen',
  },
  {
    type: 'image',
    imageUrl: '/images/Kitchen/4LDwtCyA.webp',
    category: 'kitchen',
  },
  {
    type: 'image',
    imageUrl: '/images/Kitchen/BnwvOv2g.webp',
    category: 'kitchen',
  },
  {
    type: 'image',
    imageUrl: '/images/Kitchen/dHGDEznA.webp',
    category: 'kitchen',
  },
  {
    type: 'image',
    imageUrl: '/images/Kitchen/GV4nDF7Q.webp',
    category: 'kitchen',
  },
  {
    type: 'image',
    imageUrl: '/images/Kitchen/i6CXYwWA.webp',
    category: 'kitchen',
  },
  {
    type: 'image',
    imageUrl: '/images/Kitchen/jAZgENHA.webp',
    category: 'kitchen',
  },
  {
    type: 'image',
    imageUrl: '/images/Kitchen/JQELB7tw.webp',
    category: 'kitchen',
  },
  {
    type: 'image',
    imageUrl: '/images/Kitchen/jVeT7Fuw.webp',
    category: 'kitchen',
  },
  {
    type: 'image',
    imageUrl: '/images/Kitchen/l7DHzaiA.webp',
    category: 'kitchen',
  },

  // Modern Bathroom Images
  {
    type: 'image',
    imageUrl: '/images/ModernBathroom/1.2mayford_furniture_v1_ls.jpg',
    category: 'modern-bathroom',
  },
  {
    type: 'image',
    imageUrl: '/images/ModernBathroom/apex_sliding_enclosures_v2_ls.jpg',
    category: 'modern-bathroom',
  },
  {
    type: 'image',
    imageUrl: '/images/ModernBathroom/arlo_ceramics_v2_ls1.jpg',
    category: 'modern-bathroom',
  },
  {
    type: 'image',
    imageUrl: '/images/ModernBathroom/ath077c_furniture_v1_ls1.jpg',
    category: 'modern-bathroom',
  },
  {
    type: 'image',
    imageUrl: '/images/ModernBathroom/lq043_mirrors_v1_ls.jpg',
    category: 'modern-bathroom',
  },
  {
    type: 'image',
    imageUrl: '/images/ModernBathroom/lq084_mirrors_v1_ls.jpg',
    category: 'modern-bathroom',
  },
  {
    type: 'image',
    imageUrl: '/images/ModernBathroom/maya_ceramics_v2_ls.jpg',
    category: 'modern-bathroom',
  },
  {
    type: 'image',
    imageUrl: '/images/ModernBathroom/nbb001_baths_v2_ls.jpg',
    category: 'modern-bathroom',
  },
  {
    type: 'image',
    imageUrl: '/images/ModernBathroom/roomset_athena_square_bath_v1_ls.jpg',
    category: 'modern-bathroom',
  },
  {
    type: 'image',
    imageUrl: '/images/ModernBathroom/wrsbp12_screens_v1_ls.jpg',
    category: 'modern-bathroom',
  },
  {
    type: 'image',
    imageUrl: '/images/ModernBathroom/2. sarenna_white_furniture_v1_ls1.jpg',
    category: 'modern-bathroom',
  },
  {
    type: 'image',
    imageUrl: '/images/ModernBathroom/apex_hinged_enclosures_v2_ls.jpg',
    category: 'modern-bathroom',
  },
  {
    type: 'image',
    imageUrl: '/images/ModernBathroom/apex_offset_quad_enclosures_v2_ls.jpg',
    category: 'modern-bathroom',
  },
  {
    type: 'image',
    imageUrl: '/images/ModernBathroom/off973_baths_planels_v2_ls.jpg',
    category: 'modern-bathroom',
  },
  {
    type: 'image',
    imageUrl: '/images/ModernBathroom/otley_baths_v2_ls.jpg',
    category: 'modern-bathroom',
  },
  {
    type: 'image',
    imageUrl: '/images/ModernBathroom/qua002_furniture_v1_ls2.jpg',
    category: 'modern-bathroom',
  },
  {
    type: 'image',
    imageUrl: '/images/ModernBathroom/roomset_athena_wetroom_v1_ls.jpg',
    category: 'modern-bathroom',
  },

  // Traditional Bathroom Images
  {
    type: 'image',
    imageUrl: '/images/TraditionalBathroom/back-to-wall-baths-v1-ls-671787fcb6e65.webp',
    category: 'traditional-bathroom',
  },
  {
    type: 'image',
    imageUrl: '/images/TraditionalBathroom/kensington-baths-v1-ls-671787ff60c3a.webp',
    category: 'traditional-bathroom',
  },
  {
    type: 'image',
    imageUrl: '/images/TraditionalBathroom/lof233-furniture-v1-ls1-67178811459cc.webp',
    category: 'traditional-bathroom',
  },
  {
    type: 'image',
    imageUrl: '/images/TraditionalBathroom/lof268-furniture-v2-ls1-6717881869875.webp',
    category: 'traditional-bathroom',
  },
  // {
  //   type: 'image',
  //   imageUrl: '/images/TraditionalBathroom/1-671787fb831c4.webp',
  //   category: 'traditional-bathroom',
  // },
  // {
  //   type: 'image',
  //   imageUrl: '/images/TraditionalBathroom/2-671787fb94ffb.webp',
  //   category: 'traditional-bathroom',
  // },
  // {
  //   type: 'image',
  //   imageUrl: '/images/TraditionalBathroom/3-671787fc0843c.webp',
  //   category: 'traditional-bathroom',
  // },
  {
    type: 'image',
    imageUrl: '/images/TraditionalBathroom/belgravia-671787fcae69c.webp',
    category: 'traditional-bathroom',
  },
  {
    type: 'image',
    imageUrl: '/images/TraditionalBathroom/hillingdon-671787fe38c71.webp',
    category: 'traditional-bathroom',
  },
  {
    type: 'image',
    imageUrl: '/images/TraditionalBathroom/victorian-67178826ce5b8.webp',
    category: 'traditional-bathroom',
  },
  {
    type: 'image',
    imageUrl: '/images/TraditionalBathroom/empire-671787fd7fb3d.webp',
    category: 'traditional-bathroom',
  },
];

export const fetchGalleryImages = async (category: string): Promise<ImageItem[]> => {
  return placeholderImages.filter((item) => item.category === category);
};
