import {useState } from 'react';
import KitchenBathroomHeader from '../CustomComponents/KitchenBathroomHeader';
import Head from '../PageComponents/KitchenBathroomComponent/Head';
import ImageGallery from '../PageComponents/GalleryComponent/imagegallery';
import Testimonial from '../CustomComponents/testimonialReusable';
import CTA from '../CustomComponents/cta';

export default function ModernBathroom() {
  const [modernBathroomData] = useState({
    headline: 'Modern Bathrooms',
    mainImage: '/images/modern-bathroom-main.jpg',
    description: `Our bathrooms inspire in so many ways. They are crafted with exceptional attention to detail, blending stunning aesthetics with practical design.

Not only do they look fantastic, but their quality is also proven in everyday use. Superbly manufactured bathroom furniture, paired with innovative fittings technology and carefully considered interiors, ensure that every product delivers both beauty and functionality.`,
    images: ['/images/modern1.jpg', '/images/modern2.jpg'],
  });

  return (
    <div>
      <KitchenBathroomHeader
        images={['/images/ModernBathroom/lq084_mirrors_v1_ls.jpg']}
        title="Modern Bathrooms"
        subtitle=""
        height="60vh"
      />
      <Head
        heading={modernBathroomData.headline}
        images={['/images/ModernBathroom/roomset_athena_wetroom_v1_ls.jpg']}
        paragraphs={[modernBathroomData.description]}
      />
      <Testimonial
        image="/images/davidreview.png"
        description='Advised and re designed our bathroom modification. Always on hand to answer questions and went the extra mile to make sure every last piece was as we wanted. Highly recommend. Thank you'
        name="David Dyson"
        projectType="Kitchen Renovation"
        bgColor="light"  // Light background with dark text
      />
      <CTA />
      <ImageGallery category="modern-bathroom" />
    </div>
  );
}
