import React, { createContext, useContext, ReactNode, useState } from 'react';

interface Project {
  id: number;
  title: string;
  description: string;
  location: string;
  customerName: string;
  projectType: string;
  images: string[];
  keyFeatures: string[];
}

interface ProjectsContextProps {
  projects: Project[];
}

interface ProjectsProviderProps {
  children: ReactNode;
}

// Create the Projects Context
const ProjectsContext = createContext<ProjectsContextProps | undefined>(undefined);

// Hardcoded projects data with full details
const hardcodedProjects: Project[] = [
  {
    id: 1,
    title: "Jayne's Modern Kitchen Renovation",
    description: `
      This kitchen renovation for Jayne Calder in Norwich, UK, was all about transforming an outdated space 
      into a modern kitchen that reflects both style and functionality. 
      
      The original kitchen lacked natural light and storage, but we resolved that with strategic design choices that maximized space and light.
      
      We began by installing custom cabinetry to fit Jayne’s needs, featuring soft-close technology for drawers and 
      doors to enhance the kitchen's sleek and minimalist aesthetic. 
      
      The central island serves as the heart of the kitchen, 
      designed with a built-in breakfast bar and additional storage underneath. Granite countertops were chosen for their 
      durability and timeless appeal, providing a beautiful and practical surface for cooking and preparation.
      
      To increase functionality, we installed energy-efficient Bosch appliances seamlessly into the cabinetry. Under-cabinet 
      LED lighting adds both ambiance and utility, while the open-plan layout allows for easy movement and socializing. The final 
      result is a bright, open kitchen that feels like the perfect blend of contemporary style and everyday practicality.
    `,
    location: 'Norwich, UK',
    customerName: 'Jayne Calder',
    projectType: 'Kitchen Renovation',
    images: [
      '/images/1. Jane/2.jpg',
      '/images/1. Jane/3.jpg',
      '/images/1. Jane/4.jpg',
      '/images/1. Jane/5.jpg',
      '/images/1. Jane/6.jpg',
      '/images/1. Jane/7.jpg',
      '/images/1. Jane/8.jpg',
      '/images/1. Jane/9.jpg',
      '/images/1. Jane/10.jpg',
      '/images/1. Jane/11.jpg',
      '/images/1. Jane/12.jpg',
      '/images/1. Jane/13.jpg',
      '/images/1. Jane/14.jpg',
      '/images/1. Jane/15.jpg',
      '/images/1. Jane/16.jpg',
      '/images/1. Jane/17.jpg',
      '/images/1. Jane/18.jpg',
      '/images/1. Jane/19.jpg',
      '/images/1. Jane/20.jpg',
      '/images/1. Jane/21.jpg',
      '/images/1. Jane/22.jpg',
      '/images/1. Jane/23.jpg',
      '/images/1. Jane/24.jpg',
      '/images/1. Jane/25.jpg',
      '/images/1. Jane/26.jpg',
      '/images/1. Jane/27.jpg',
      '/images/1. Jane/28.jpg',
      '/images/1. Jane/29.jpg',
      '/images/1. Jane/30.jpg',
      '/images/1. Jane/31.jpg',
      '/images/1. Jane/32.jpg',
      '/images/1. Jane/33.jpg',
      '/images/1. Jane/34.jpg',
      '/images/1. Jane/35.jpg',
      '/images/1. Jane/36.jpg',
      '/images/1. Jane/37.jpg',
      '/images/1. Jane/38.jpg',
      '/images/1. Jane/39.jpg',
      '/images/1. Jane/40.jpg',
      '/images/1. Jane/41.jpg',
      '/images/1. Jane/42.jpg',
    ],
    keyFeatures: [
      'Custom cabinets with soft-close technology',
      'Granite countertops with waterfall edges',
      'Energy-efficient Bosch appliances',
      'Under-cabinet LED lighting',
      'Central island with breakfast bar and additional storage',
      'Integrated wine cooler',
    ],
  },
  {
    id: 2,
    title: "Peter's Kitchen Renovation",
    description: `
      Peter's kitchen renovation was a collaborative effort where we supplied and designed the space, 
      while Peter took on the installation. The design features a balance of modern and traditional 
      elements, blending sleek finishes with natural materials. The kitchen layout was planned to 
      maximize both storage and workspace, making it as functional as it is stylish. From the custom 
      cabinetry to the high-end countertops and appliances, every detail was carefully considered to 
      ensure that the kitchen meets the demands of daily life while being a beautiful centerpiece of the home.
    `,
    location: 'Norwich, UK',
    customerName: 'Peter',
    projectType: 'Kitchen Renovation',
    images: [
      '/images/2. Peters/1.jpg',
      '/images/2. Peters/2.jpg',
      '/images/2. Peters/3.jpg',
      '/images/2. Peters/4.jpg',
      '/images/2. Peters/5.jpg',
      '/images/2. Peters/6.jpg',
      '/images/2. Peters/7.jpg',
      '/images/2. Peters/8.jpg',
    ],
    keyFeatures: [
      'Supplied and designed custom kitchen',
      'Modern layout with natural materials',
      'High-end appliances and finishes',
      'Maximized storage with smart cabinetry solutions',
      'Ample countertop workspace for daily use',
    ],
  },  
  {
    id: 3,
    title: "Tony's Kitchen Design",
    description: `
      This kitchen design for Tony focuses on a sleek, modern aesthetic that complements the existing 
      architecture of his home. The design maximizes the space available, incorporating custom cabinetry 
      and high-end finishes to create a functional yet stylish environment. While Tony handled the installation, 
      we provided a detailed design that blends contemporary elements with traditional touches, ensuring the 
      kitchen is both practical and visually striking. Natural light floods the space, creating a warm and 
      welcoming atmosphere.
    `,
    location: 'Norwich, UK',
    customerName: 'Tony',
    projectType: 'Kitchen Design',
    images: [
      '/images/3. Tony/1.jpg',
      '/images/3. Tony/2.jpg',
      '/images/3. Tony/3.jpg',
      '/images/3. Tony/4.jpg',
      '/images/3. Tony/5.jpg',
    ],
    keyFeatures: [
      'Custom-designed kitchen layout',
      'High-end finishes with a modern aesthetic',
      'Ample storage with custom cabinetry',
      'Large windows to maximize natural light',
      'Designed for practical daily use with a stylish flair',
    ],
  },
  {
    id: 4,
    title: 'Bathroom Conversion for Richard',
    description: `
      This bathroom conversion maximizes the available space while incorporating luxurious fixtures and finishes. 
      Richard wanted a modern design with practical features, so we incorporated custom cabinetry, elegant tiling, 
      and energy-efficient lighting. The result is a bathroom that is both functional and stylish.
    `,
    location: 'Norwich, UK',
    customerName: 'Richard Forster',
    projectType: 'Bathroom Conversion',
    images: [
      '/images/Cloakroom Conversion/1.jpg',
      '/images/Cloakroom Conversion/2.jpg',
      '/images/Cloakroom Conversion/3.jpg',
      '/images/Cloakroom Conversion/6.jpg',
    ],
    keyFeatures: [
      'Custom fixtures and cabinetry',
      'Luxury tiles for floors and walls',
      'Energy-efficient lighting',
      'Smart mirror with built-in lighting',
    ],
  },
  {
    id: 5,
    title: 'Jayne’s Bathroom',
    description: `
      Jayne’s bathroom is an elegant space that merges contemporary design with high-end materials. 
      The bathroom features a freestanding tub, modern vanity, and premium fixtures. Jayne wanted 
      a relaxing yet practical space, and we provided a solution that met all her needs while maintaining a luxurious feel.
    `,
    location: 'Norwich, UK',
    customerName: 'Jayne Calver',
    projectType: 'Bathroom',
    images: [
      '/images/Janes Bathroom/1.jpg',
      '/images/Janes Bathroom/2.jpg',
      '/images/Janes Bathroom/3.jpg',
      '/images/Janes Bathroom/4.jpg',
      '/images/Janes Bathroom/5.jpg',
    ],
    keyFeatures: [
      'Freestanding bathtub',
      'Modern vanity with soft-close drawers',
      'Premium fixtures and finishes',
      'Elegant lighting with dimmable controls',
    ],
  },
  {
    id: 6,
    title: 'Main Bathroom Renovation',
    description: `
      This main bathroom renovation was all about transforming a small, outdated space into a modern, stylish bathroom. 
      We installed a walk-in shower, high-end tiles, and sleek, modern fixtures that added a touch of luxury while maintaining practicality.
    `,
    location: 'Norwich, UK',
    customerName: 'Richard Forster',
    projectType: 'Bathroom',
    images: [
      '/images/MainBathroom/1.jpg',
      '/images/MainBathroom/2.jpg',
      '/images/MainBathroom/1.png',
      '/images/MainBathroom/5.jpg',
    ],
    keyFeatures: [
      'Walk-in shower with glass enclosure',
      'Heated towel rail',
      'High-end floor and wall tiles',
      'Energy-efficient LED lighting',
    ],
  },        
];

// Projects Provider Component
export const ProjectsProvider: React.FC<ProjectsProviderProps> = ({ children }) => {
  const [projects] = useState<Project[]>(hardcodedProjects); // Using hardcoded data

  return (
    <ProjectsContext.Provider value={{ projects }}>
      {children}
    </ProjectsContext.Provider>
  );
};

// Hook to use projects data
export const useProjects = () => {
  const context = useContext(ProjectsContext);
  if (!context) {
    throw new Error('useProjects must be used within a ProjectsProvider');
  }
  return context;
};
